.RankingView {
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 20;
  text-align: center;
  position: relative;
}

.RankingViewClose {
  font-family: sans-serif !important;
  color: white;
  position: absolute;
  right: 0;
  font-family: monospace;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.RankingViewTitle {
  user-select: none;
  padding-bottom: 5px;
  color: #0000ff;
  font-family: "SuperM";
}

.RankingViewTable {
  margin: auto;
  table-layout: fixed;
}

.RankingViewTable tr.GameTableHead {
  line-height: 18px;
}

.RankingViewTable tr.GameTableHead td {
  text-align: center !important;
}

.RankingViewTable tr td.short {
  width: 44px !important;
}

.text-center {
  text-align: center !important;
}

.RankingViewTable tr.red td {
  color: #ff0000;
}

.RankingViewTable tr.pink td {
  color: #fe3795;
}

.RankingViewTable tr.yellow td {
  color: #ffff00;
}

.RankingViewTable tr.green td {
  color: #00ff00;
}

.RankingViewTable tr.aqua td {
  color: #00ffff;
}

.RankingViewTable tr td.medium {
  width: 55px !important;
  text-align: center;
}

.RankingViewTable tr td {
  text-transform: lowercase;
  font-family: "SuperM";
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding: 10px 4px;
  font-size: 12px;
}