.GameViewInfo {
  padding: 5px;
}

.GameViewBoard {
  display: flex;
  flex-direction: column;
}

.GameViewPlay {
  position: absolute;
  width: 91%;
  background: #000;
  z-index: 10;
  text-align: center;
  margin: auto;
  padding-top: 25%;
}

.GameViewTitle {
  user-select: none;
  padding-bottom: 5px;
  color: white;
  font-family: "SuperM";
  font-size: 30px;
}

.GameViewPoints {
  color: white;
  margin-bottom: 35px;
}

.GameViewFirstItem {
  position: absolute;
  background: #ffffff82;
  width: 52px;
  height: 52px;
  left: -1px;
  top: -1px;
}