html,
body {
  background: black !important;
  touch-action: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.animation {
  transition: all 0.6s ease;
}

* {
  font-family: "SuperM" !important;
}

#root {
  user-select: none;
}