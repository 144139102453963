.GameOverViewTitle {
  user-select: none;
  padding-bottom: 5px;
  color: white;
  font-family: "SuperM";
  font-size: 30px;
}

.GameOverViewGoal {
  user-select: none;
  padding-bottom: 5px;
  color: white;
  font-family: "SuperM";
  font-size: 16px;
  line-height: 50px;
}