.PopUp {
  position: absolute;
  color: #222;
  background: #fff;
  z-index: 10;
  width: 320px;
}

.PopUpBtn {
  cursor: pointer;
  border: 0;
  text-align: center;
  font-size: 15px;
  width: 40%;
  transition: all 0.1s ease;
  padding: 10px 10px;
  margin-right: 5%;
  margin-left: 5%;
}

.PopUpTitle {
  text-align: center;
}

.PopUpDescription {
  color: #222;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
}

.PopUpBody {
  text-align: center;
}