.NoConnection {
  background: black;
  position: absolute;
  z-index: 25;
}

.NoConnectionTitle {
  text-align: center;
  color: white;
  padding: 10px;
}

.NoConnectionBody {
  text-align: center;
}