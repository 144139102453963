.Timer {
  margin-top: 10px;
  border: 2px solid white;
  background: #f8d848;
}

.Timer .Bar {
  background: black;
  padding: 4px;
}

.Timer .Bar span {
  color: white;
  font-family: "SuperM";
  font-size: 22px;
  line-height: 0px;
}